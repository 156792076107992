import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/1.jpg';
// import p2 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/2.jpg';
// import p3 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/3.jpg';
// import p4 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/4.jpg';
// import p5 from 'assests/Photos/LifeSkill2023/Roadsafty/5.jpg';
// import p6 from 'assests/Photos/LifeSkill2023/Roadsafty/6.jpg';
// import p7 from 'assests/Photos/LifeSkill2023/Roadsafty/7.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/LifeSkill2023/Sidebar2023';
import Sidebar2024 from '../LifeSkill2023/Sidebar2024';
import Archive2022 from '../LifeSkill2023/Archive2022';

const Firesafety2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  
  useEffect(() => {
    fetch('/config.json') // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/events-activities/life-skill/FireSafety/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/life-skill/FireSafety/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/life-skill/FireSafety/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/life-skill/FireSafety/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/life-skill/FireSafety/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/life-skill/FireSafety/6.webp`;



  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1.2,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1.2,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },

    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },

    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },

      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      FIRE SAFETY DRILL
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class:  6-8	  Date: 25 September 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        
        Students from classes 6 to 8 observed a fire safety drill led by experts from the Department of Fire and Emergency Services. The experts arrived with a fire engine and fire safety equipment to educate both staff and students on life-saving and rescue techniques in case of a fire.
        <br/>
        
        They explained that fire hazards can be categorized based on the physical state of the combustible material, including solid (wood, paper), liquid (gasoline, alcohol), gaseous (propane, methane) and electrical (faulty wiring, appliances) risks, each requiring specific safety measures. They also conducted demonstrations and engaged students in discussions about various strategies, pipes and types of nozzles, all essential components in fire fighting and water distribution systems, tailored to meet the needs of fire suppression, water supply and hydraulic efficiency.
        <br/>
        The experts praised the school's high level of preparedness, highlighting the clearly displayed floor-wise evacuation plans on each level and the strategically placed fire extinguishers. The primary aim of the exercise was to increase awareness among students about fire fighting techniques and how to respond swiftly in emergencies.
        <br/>
        The fire safety drill concluded with encouragement for student participation in future safety workshops and gratitude for the collaboration with the Department of Fire and Emergency Services. The school is committed to fostering a culture of safety, incorporating regular training and involving the community to enhance overall emergency preparedness.
        <br/>
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “OPPORTUNITIES DON'T HAPPEN, YOU CREATE THEM”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024/>
             </Box>
             <Box marginBottom={4}>
               <Archive2022/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Firesafety2024;